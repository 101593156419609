.custom-checkbox {
  color: #2b513a; /* This will change the color of the checkbox when checked */
  border: none; /* Border color for the checkbox */
  width: 16px;
  height: 16px;
}

.custom-checkbox:checked {
  background-color: #2b513a !important; /* Ensures checkbox background is green when checked */
}
