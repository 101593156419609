.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-height: 550px) {
  .home-bg {
    min-height: 105vh !important;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.spinner {
  animation: spin 0.1s linear;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

@media (min-width: 1200px) {
  .swiper {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .swiper {
    max-width: 1100px;
  }
}

@media (max-width: 600px) {
  .swiper {
    max-width: 300px;
  }
}

.tail-shadow {
  position: relative; /* Ensure this is set on the parent element */
  display: inline-block; /* Or 'block', depending on your layout needs */
}

.tail-shadow::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Adjust as needed to position the shadow below the element */
  left: 50%; /* Start from the center of the parent */
  width: 131%; /* Adjust the width as needed */
  height: 30px; /* Height of the shadow */
  background: rgba(32, 64, 45, 0.5); /* Temporary color for visibility */
  z-index: -1; /* Below the parent element but ensure it doesn’t go under other background elements */
  transform: translateX(-39%); /* Shift it back to truly center it */
  filter: blur(10px); /* Adjust as needed */
}

@media screen and (max-width: 600px) {
  .tail-shadow::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Adjust as needed to position the shadow below the element */
    left: 50%; /* Start from the center of the parent */
    max-width: 150%; /* Adjust the width as needed */
    height: 30px; /* Height of the shadow */
    background: rgba(32, 64, 45, 0.5); /* Temporary color for visibility */
    z-index: -1; /* Below the parent element but ensure it doesn’t go under other background elements */
    transform: translateX(-36%); /* Shift it back to truly center it */
    filter: blur(10px); /* Adjust as needed */
  }
}

.tail-shadow2 {
  position: relative; /* Ensure this is set on the parent element */
  display: inline-block; /* Or 'block', depending on your layout needs */
}

.tail-shadow2::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Adjust as needed to position the shadow below the element */
  left: 50%; /* Start from the center of the parent */
  width: 100%; /* Adjust the width as needed */
  height: 45px; /* Height of the shadow */
  background: rgba(32, 64, 45, 0.561); /* Temporary color for visibility */
  z-index: -1; /* Below the parent element but ensure it doesn’t go under other background elements */
  transform: translateX(-50%); /* Shift it back to truly center it */
  filter: blur(10px); /* Adjust as needed */
}

.tail-shadow3 {
  position: relative; /* Ensure this is set on the parent element */
  display: inline-block; /* Or 'block', depending on your layout needs */
}

.tail-shadow3::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Adjust as needed to position the shadow below the element */
  left: 50%; /* Start from the center of the parent */
  width: 100%; /* Adjust the width as needed */
  height: 45px; /* Height of the shadow */
  background: rgba(32, 64, 45, 0.518); /* Temporary color for visibility */
  z-index: -1; /* Below the parent element but ensure it doesn’t go under other background elements */
  transform: translateX(-50%); /* Shift it back to truly center it */
  filter: blur(10px); /* Adjust as needed */
}

.tail-shadow4 {
  position: relative; /* Ensure this is set on the parent element */
  display: inline-block; /* Or 'block', depending on your layout needs */
}

.tail-shadow4::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Adjust as needed to position the shadow below the element */
  left: 50%; /* Start from the center of the parent */
  width: 100%; /* Adjust the width as needed */
  height: 20px; /* Height of the shadow */
  background: rgba(32, 64, 45, 0.518); /* Temporary color for visibility */
  z-index: -1; /* Below the parent element but ensure it doesn’t go under other background elements */
  transform: translateX(-50%); /* Shift it back to truly center it */
  filter: blur(10px); /* Adjust as needed */
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(
      20px
    ); /* Optional: start slightly below to combine fade-in with slide-in */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation class */
.fade-in-animation {
  animation: fadeIn 1s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners for Firefox */
.custom-number-input {
  -moz-appearance: textfield;
}
