.containerSlider {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.item {
  display: flex;
  height: auto;
  transform: translate3d(0, 0, 0);
  align-items: end;

  > div {
    height: 100%;
  }
}
